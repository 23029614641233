import tw, { styled } from "twin.macro"

const StyledRequirements = styled.section`
  ${tw`md:py-12 py-6`};
  .main-wrapper {
    ${tw`flex flex-col 2xl:px-[160px] sm:px-[50px] px-[10px] gap-4  mx-auto z-[1] relative`}

    h2 {
      ${tw`md:text-2xl text-xl text-secondary font-semibold`}
    }

    ul {
      ${tw`flex flex-col gap-2 font-medium`}
      li {
        ${tw`text-white/90 list-disc ml-4`}
      }
    }

    ol {
      ${tw`flex flex-col gap-2 font-medium`}
      li {
        ${tw`text-white/90 list-[number] ml-4`}
      }

      ol {
        li {
          ${tw`list-[lower-alpha]`}
        }
      }
    }
  }
`

export default StyledRequirements
