import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Banner from "../components/career-details/Banner"
import Requirements from "../components/career-details/Requirements"
import CareersForm from "../components/careers/CareersForm"
import SEO from "../components/seo"

const CareerDetails = ({ data }) => {
  const careerData = data.markdownRemark.frontmatter

  return (
    <Layout>
      {/* <Banner /> */}
      <SEO
        title="Careers at Cresol Infoserv"
        description="Join a team of innovators and digital experts at Cresol Infoserv. Explore exciting career opportunities and take your professional journey to new heights."
        keywords="best website development company, website design and development services, best digital marketing services, software testing services, mobile app development company"
      />
      <Requirements
        data={careerData.details}
        graduateDesc={careerData.graduatesDesc}
      />
      <CareersForm />
    </Layout>
  )
}

export default CareerDetails

export const pageQuery = graphql`
  query CareerBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        graduatesDesc
        details {
          requirements {
            description
          }
        }
      }
    }
  }
`
