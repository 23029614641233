import React from "react"
import StyledRequirements from "./style/requirements"

const Requirements = ({ data, graduateDesc }) => {
  return (
    <StyledRequirements>
      <div className="main-wrapper">
        {data.requirements && <h2>Requirements</h2>}
        <ul>
          {data.requirements &&
            data.requirements.map((d, i) => <li key={i}>{d.description}</li>)}
        </ul>
        {graduateDesc && (
          <div dangerouslySetInnerHTML={{ __html: graduateDesc }} />
        )}
      </div>
    </StyledRequirements>
  )
}

export default Requirements
